<template>
  <div>
    <form id="generalForm" class="form-layout form-layout-2">
      <div class="alert alert-danger" v-if="hasWithoutOperationalTeamAlert">
        <span class="text-bold uppercase">
          <i class="fa fa-exclamation-circle"></i>
          Cliente sem time operacional informado
        </span>
        <br />
        <span>
          Atenção! o cliente deste processo não possui time operacional
          informado para processos do tipo
          <strong>{{ model.typeOfProcess }}</strong
          >!
        </span>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group flex fd-c jc-c">
            <input
              v-model="model.identification"
              class="form-control tx-34 tx-center tx-bolder tx-white"
              name="identification"
              readonly
              @click="changeIdentification"
            />
            <label class="form-control-label tx-center tx-thin">
              Referência Interna
              <br />
              <span class="tx-bold">
                {{ model.account ? model.account.alias : "" }}
              </span>
            </label>
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <div class="row no-gutters">
              <div class="form-group bd-l-0-force col-md-12">
                <div class="flex gap1 ai-c">
                  <label class="form-control-label"
                    >Status (Situação Rápida)</label
                  >
                  <small v-if="model.statusLastModifiedBy">
                    (Alterado pela última vez em
                    {{
                      model.statusDateOfLastModify
                        | moment("DD/MM/YY à\\s H:mm:ss")
                    }}
                    por {{ model.statusLastModifiedBy }})
                  </small>
                </div>
                <input
                  v-model="model.status"
                  class="form-control tx-20"
                  name="status"
                  type="text"
                />
              </div>
              <hr />
              <div
                class="form-group bd-l-0-force bd-t-0-force col-md-12 bg-gray-100"
              >
                <div class="flex gap1 ai-c">
                  <label class="form-control-label"
                    >Status Despachante Aduaneiro</label
                  >
                  <small v-if="model.statusCustomBrokerLastModifiedBy">
                    (Alterado pela última vez em
                    {{
                      model.statusCustomBrokerDateOfLastModify
                        | moment("DD/MM/YY à\\s H:mm:ss")
                    }}
                    por {{ model.statusCustomBrokerLastModifiedBy }})
                  </small>
                </div>
                <span class="form-control tx-20 no-click-not-allowed">{{
                  model.statusCustomBroker != null &&
                  model.statusCustomBroker != ""
                    ? model.statusCustomBroker
                    : "N/I"
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group flex fd-c jc-c bd-l-0-force">
            <div class="input-group-solved ai-c">
              <div class="input-group-solved-before">
                <toggle-button
                  v-model="model.duimp"
                  :color="{ unchecked: '#bf0909' }"
                  :height="14"
                  :labels="{ checked: 'SIM', unchecked: 'NÃO' }"
                  :sync="true"
                  name="duimp"
                />
              </div>
              <div class="input-group-solved-after wd-150 mg-l-10">
                <label class="form-control-label">Duimp</label>
              </div>
            </div>
            <div class="input-group-solved ai-c">
              <div class="input-group-solved-before">
                <toggle-button
                  v-model="model.importLicense"
                  :color="{ unchecked: '#bf0909' }"
                  :height="14"
                  :labels="{ checked: 'SIM', unchecked: 'NÃO' }"
                  :sync="true"
                  name="importLicense"
                />
              </div>
              <div class="input-group-solved-after wd-150 mg-l-10">
                <label class="form-control-label">Licenças</label>
              </div>
            </div>

            <div class="input-group-solved ai-c">
              <div class="input-group-solved-before">
                <toggle-button
                  v-model="model.urgent"
                  :color="{ unchecked: '#bf0909' }"
                  :height="14"
                  :labels="{ checked: 'SIM', unchecked: 'NÃO' }"
                  :sync="true"
                  name="urgent"
                />
              </div>
              <div class="input-group-solved-after wd-12 0 mg-l-10">
                <label class="form-control-label">Urgente</label>
              </div>
            </div>

            <div class="input-group-solved ai-c">
              <div class="input-group-solved-before">
                <toggle-button
                  v-model="model.imo"
                  :color="{ unchecked: '#bf0909' }"
                  :height="14"
                  :labels="{ checked: 'SIM', unchecked: 'NÃO' }"
                  :sync="true"
                  name="imo"
                />
              </div>
              <div class="input-group-solved-after wd-12 0 mg-l-10">
                <label class="form-control-label">IMO</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bg-gray-100 bd-b-0-force mg-md-t--1">
            <label class="form-control-label"> Tipo de Processo </label>
            <select
              v-model="model.typeOfProcess"
              class="custom-select form-control"
              name="typeOfProcess"
              @change.prevent="changeTypeOfProcess"
            >
              <option value="">Nenhuma</option>
              <option
                v-for="typeOfProcess in listOfTypeOfProcess"
                :key="typeOfProcess.key"
                :value="typeOfProcess.key"
              >
                {{ typeOfProcess.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-4 bg-gray-100">
          <div class="form-group bd-t-0-force bd-l-0-force">
            <label class="form-control-label"> Empresa / Filial </label>
            <v-select
              v-model="model.account"
              :on-search="getAccounts"
              :options="search_accounts"
              label="name"
              name="account"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} <strong>{{ option.alias }}</strong>
              </template>
            </v-select>
            <span
              v-show="errors.has('search_accounts')"
              class="parsley-errors-list filled"
              >Selecione a Empresa</span
            >
          </div>
        </div>

        <div class="col-4">
          <div
            class="form-group bd-t-0-force bd-l-0-force bd-b-0-force mg-md-t--1"
          >
            <label class="form-control-label"> Despachante Aduaneiro </label>
            <v-select
              v-model="model.customBroker"
              :on-search="getCustomersBrokers"
              :options="optionsCustomersBrokers"
              label="name"
              name="customBroker"
            >
              <template slot="option" slot-scope="option"
                >{{ option.federalID }} | {{ option.name }}</template
              >
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>

        <div class="col-md-2">
          <div
            class="form-group mg-md-t--1 bd-t-1-force bd-l-0-force bd-b-0-force"
          >
            <label class="form-control-label">Destinação Mercadorias</label>
            <select
              v-model="model.useOfGoods"
              class="custom-select form-control"
              name="useOfGoods"
            >
              <option value="">Nenhuma</option>
              <option
                v-for="typeUseOfGood in listOfUseOfGoods"
                :key="typeUseOfGood.key"
                :value="typeUseOfGood.key"
              >
                {{ typeUseOfGood.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mg-md-t--1">
            <label class="form-control-label">Cliente</label>

            <div class="flex ai-c fgap1 jc-sb">
              <multi-select
                telao
                :config="{
                  options: customers,
                  closeOnSelect: true,
                  label: 'name',
                  trackBy: 'id',
                  placeholder: 'Selecione um cliente',
                }"
                v-model="model.customer"
              >
                <template #noResult>
                  Nenhum cliente encontrado. Deseja
                  <a href="#" @click.prevent="modalContactOpen = true"
                    >criar um novo cliente</a
                  >?
                </template>
              </multi-select>

              <span
                v-if="model.customer && model.customer.salesRadarModality"
                class="badge badge-light tx-12"
                style="cursor: default; border-radius: 7px"
              >
                RADAR
                <strong v-if="model.customer.salesRadarModality === 'EXPRESS'"
                  >EXPRESSO</strong
                >
                <strong
                  v-if="model.customer.salesRadarModality === 'LIMITED_50'"
                  >LIMITADO USD 50k</strong
                >
                <strong v-if="model.customer.salesRadarModality === 'LIMITED'"
                  >LIMITADO USD 150k</strong
                >
                <strong v-if="model.customer.salesRadarModality === 'ILIMITED'"
                  >ILIMITADO</strong
                >
              </span>
              <span
                v-if="model.customer && model.customer.hasProductsCatalog"
                class="badge tx-12"
                style="
                  cursor: default;
                  color: white;
                  background-color: #00a068;
                  border-radius: 7px;
                "
                title="Catálogo de Produtos Prime"
              >
                <strong>CATÁLOGO</strong>
              </span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mg-md-t--1 bd-l-0-force">
            <label class="form-control-label">Ref Pedido/ Cliente</label>
            <input
              v-model="model.customerRef"
              class="form-control"
              name="customerRef"
              type="text"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mg-md-t--1 bd-t-0-force">
            <label class="form-control-label">Exportador</label>
            <v-select
              v-model="model.exporter"
              :on-search="getExporters"
              :options="optionsExporters"
              label="name"
              name="exporter"
            >
              <template slot="option" slot-scope="option"
                >{{ option.federalID }} | {{ option.name }}
              </template>
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mg-md-t--1 bd-l-0-force bd-t-0-force">
            <label class="form-control-label">Fabricante/Produtor</label>
            <v-select
              v-model="model.manufacturer"
              :on-search="getExporters"
              :options="optionsExporters"
              label="name"
              name="manufacturer"
            >
              <template slot="option" slot-scope="option"
                >{{ option.federalID }} | {{ option.name }}
              </template>
            </v-select>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mg-md-t--1 bd-t-0-force">
            <label class="form-control-label">Invoice</label>
            <input
              v-model="model.invoice"
              class="form-control"
              name="invoice"
              type="text"
            />
          </div>
        </div>

        <div class="col-2">
          <div class="form-group mg-md-t--1 bd-l-0-force bd-t-0-force">
            <label class="form-control-label">Data da Invoice</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.invoiceDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="invoiceDate"
                  lang="pt-br"
                  name="invoiceDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mg-md-t--1 bd-l-0-force bd-t-0-force">
            <label class="form-control-label">Local de Embarque</label>
            <input
              v-model="model.placeOfLoading"
              :disabled="abertaField"
              class="form-control"
              name="placeOfLoading"
              type="text"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mg-md-t--1 bd-t-0-force">
            <label class="form-control-label"
              >Valor Total de Invoices (Previsto)</label
            >
            <money
              v-model="model.invoiceTotal"
              class="form-control"
              name="invoiceTotal"
              type="text"
              v-bind="money"
            />
            <span class="badge badge-danger" v-if="invoiceInconsistence">
              {{ invoiceInconsistence }}
            </span>
          </div>
        </div>

        <div class="col-2">
          <div class="form-group mg-md-t--1 bd-l-0-force bd-t-0-force">
            <label class="form-control-label"> Valor VMLD </label>
            <money
              v-model="model.cifValue"
              class="form-control"
              name="cifValue"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-2">
          <div class="form-group mg-md-t--1 bd-l-0-force bd-t-0-force">
            <label class="form-control-label">Moeda (VMLD / Invoice)</label>
            <v-select
              :value="model.cifValueCurrency"
              @input="(coin) => (model.cifValueCurrency = coin.value)"
              :options="coinOptions"
              label="label"
              :clearable="false"
              name="cifValueCurrency"
            >
            </v-select>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mg-md-t--1 bd-l-0-force bd-t-0-force">
            <label class="form-control-label"> Outra Referência</label>
            <input
              v-model="model.anotherRef"
              class="form-control"
              name="anotherRef"
              type="text"
            />
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Informações de Logística</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Incoterm</label>
            <!-- v-model="model.incoterm" -->
            <select
              :value="model.incoterm"
              @change="validaIncoterm"
              class="custom-select form-control wd-150"
              name="incoterm"
            >
              <option value></option>
              <option
                v-for="incoterm in listOfIncoterms"
                :key="incoterm.key"
                :value="incoterm.key"
              >
                {{ incoterm.key }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Via de transporte</label>
            <div class="flex ai-c fw-w">
              <v-select
                v-model="model.wayOfTransport"
                :options="listOfWayOfTransport"
                index="key"
                label="value"
                name="shipowner"
                @input="changeWayOfTransport"
                style="flex: 1"
                class="input-way-of-transport"
              >
                <template slot="option" slot-scope="option">
                  {{ option.value }}
                </template>
              </v-select>
              <span
                v-show="
                  (model.wayOfTransport == 'MARITIMA' ||
                    model.wayOfTransport == 'AEREA') &&
                  model.daysToStepCashRequestCalculation != null
                "
                class="badge badge-light tx-12"
                style="cursor: default; border-radius: 7px"
              >
                <strong>
                  {{ model.daysToStepCashRequestCalculation }} DIAS
                </strong>
              </span>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Armador</label>
            <v-select
              v-model="model.shipowner"
              :on-search="getShipowners"
              :options="optionsShipowners"
              label="name"
              name="shipowner"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-t--1 bd-r-0-force">
            <label class="form-control-label">BL/AWB/CRT</label>
            <input
              v-model="model.billOfLading"
              :disabled="abertaField"
              class="form-control"
              name="billOfLading"
              type="text"
            />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mg-md-t--1">
            <label class="form-control-label">MASTER</label>
            <input
              v-model="model.billOfLadingMaster"
              :disabled="abertaField"
              class="form-control"
              name="billOfLadingMaster"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Tipo de embarque</label>
            <select
              v-model="model.typeOfBoarding"
              class="custom-select form-control wd-150"
              name="typeOfBoarding"
            >
              <option value="SEM">Nenhum</option>
              <option
                v-for="typeOfBoarding in listOfTypeOfBoarding"
                :key="typeOfBoarding.key"
                :value="typeOfBoarding.key"
              >
                {{ typeOfBoarding.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label"> Freetime </label>
            <input
              v-model="model.freetime"
              :disabled="abertaField"
              class="form-control"
              name="freetime"
              type="tel"
              v-mask="'###'"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label"> Veículo / Navio </label>
            <input
              v-model="model.vehicle"
              :disabled="abertaField"
              class="form-control"
              name="vehicle"
              type="text"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1">
            <label class="form-control-label">Navio de Embarque </label>
            <input
              v-model="model.vehicleTranshipment"
              class="form-control"
              name="vehicleTranshipment"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Forwarder</label>
            <v-select
              v-model="model.freightForwarder"
              :on-search="getForwarders"
              :options="optionsForwarders"
              label="name"
              name="freightForwarder"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1 bd-r-0-force">
            <label class="form-control-label">Porto de Destino</label>
            <v-select
              v-model="model.destinationPort"
              :on-search="getCustomsEnclosures"
              :options="optionsCustomsEnclosures"
              label="alias"
              name="destinationPort"
            >
              <template slot="option" slot-scope="option">
                {{ option.code }} | {{ option.alias }}
              </template>
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1">
            <label class="form-control-label">Recinto Alfandegado</label>
            <v-select
              v-model="model.customsEnclosure"
              :on-search="getCustomsEnclosures"
              :options="optionsCustomsEnclosures"
              label="alias"
              name="customsEnclosure"
            >
              <template slot="option" slot-scope="option">
                {{ option.code }} | {{ option.alias }}
              </template>
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Transportadora</label>
            <v-select
              v-model="model.conveyor"
              :on-search="getCompanies"
              :options="optionsCompanies"
              label="name"
              name="conveyor"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
              <slot name="no-options">Não encontrado</slot>
            </v-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Armazém Geral</label>
            <v-select
              v-model="model.generalWarehouse"
              :on-search="getCompanies"
              :options="optionsCompanies"
              label="name"
              name="generalWarehouse"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1">
            <label class="form-control-label">Nº CE-Mercante</label>
            <input
              v-model="model.ceMercante"
              :disabled="abertaField"
              class="form-control"
              name="ceMercante"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-6">
          <label class="section-title mg-t-10">Etapas/Seguro</label>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Planilha</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.stepSpreadsheet"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="stepSpreadsheet"
                  lang="pt-br"
                  name="stepSpreadsheet"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after"></div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-t--1 bd-r-0-force">
            <label class="form-control-label">Quem vai fazer o seguro?</label>
            <div class="input-group-solved">
              <!-- v-model="model.stepInsuranceValueResponsability" -->
              <select
                :value="model.stepInsuranceValueResponsability"
                @change="validaResponsavelSeguro"
                class="custom-select form-control"
                name="stepInsuranceValueResponsability"
              >
                <option value="PRIME">A Prime</option>
                <option value="CLIENTE">O Cliente</option>
                <option value="NAO_SE_APLICA">Não se Aplica</option>
              </select>
            </div>
            <!--          <div class="badge badge-info mt-1 p-1">Resposável Padrão: <span v-if="model.customer && model.customer.insuranceWithPrime">Prime</span><span class="text-medium" v-else> Cliente</span></div>-->
          </div>
        </div>

        <div class="col-4 mg-l-0">
          <div class="form-group mg-md-t--1 bd-l-0-force">
            <label class="form-control-label">Resposável Padrão</label>
            <div class="input-group-solved-after">
              <div
                class="input-group-solved tx-normal tx-black"
                v-if="model.customer && model.customer.insuranceWithPrime"
              >
                <div class="input-group-solved-before">
                  <i class="fa fa-thumbs-up tx-success"></i>
                </div>
                <div class="input-group-solved-center">Prime</div>
              </div>
              <div class="input-group-solved tx-normal tx-black" v-else>
                <div class="input-group-solved-before">
                  <i class="fa fa-thumbs-down tx-danger"></i>
                </div>
                <div class="input-group-solved-center">Cliente</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-8">
          <label class="section-title mg-t-10">Documentos Originais</label>
        </div>
        <div class="col-md-4">
          <label class="section-title mg-t-10">Conferência</label>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Documentos</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.originalsDocumentsDate"
                  v-validate="'required'"
                  :disabled="abertaField"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="originalsDocumentsDate"
                  lang="pt-br"
                  name="originalsDocumentsDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.originalsDocumentsDateSolved"
                    name="originalsDocumentsDateSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Conhecimento</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.originalsBillOfLadingDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="originalsBillOfLadingDate"
                  lang="pt-br"
                  name="originalsBillOfLadingDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.originalsBillOfLadingDateSolved"
                    name="originalsBillOfLadingDateSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Fatura</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.originalsInvoiceDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="originalsInvoiceDate"
                  lang="pt-br"
                  name="originalsInvoiceDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.originalsInvoiceDateSolved"
                    name="originalsInvoiceDateSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Packing List</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.originalsPackingListDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="originalsPackingListDate"
                  lang="pt-br"
                  name="originalsPackingListDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.originalsPackingListDateSolved"
                    name="originalsPackingListDateSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group mg-md-t--1 pd-b-0--force">
            <div class="row">
              <div class="col-lg-6">
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <toggle-button
                      v-model="model.conferenceDocumentsSolved"
                      :color="{ unchecked: '#bf0909' }"
                      :height="16"
                      :labels="{ checked: 'CONF', unchecked: 'PEND' }"
                      :sync="true"
                      :width="60"
                      name="conferenceDocumentsSolved"
                    />
                  </div>
                  <div class="input-group-solved-after wd-100 mg-l-10">
                    <label class="form-control-label">Documentos</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <toggle-button
                      v-model="model.conferenceBillOfLadingSolved"
                      :color="{ unchecked: '#bf0909' }"
                      :height="16"
                      :labels="{ checked: 'CONF', unchecked: 'PEND' }"
                      :sync="true"
                      :width="60"
                      name="conferenceBillOfLadingSolved"
                    />
                  </div>
                  <div class="input-group-solved-after wd-100 mg-l-10">
                    <label class="form-control-label">Conhecimento</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <toggle-button
                      v-model="model.conferenceInvoiceSolved"
                      :color="{ unchecked: '#bf0909' }"
                      :height="16"
                      :labels="{ checked: 'CONF', unchecked: 'PEND' }"
                      :sync="true"
                      :width="60"
                      name="conferenceInvoiceSolved"
                    />
                  </div>
                  <div class="input-group-solved-after wd-100 mg-l-10">
                    <label class="form-control-label">Fatura/Invoice</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <toggle-button
                      v-model="model.conferencePackingListSolved"
                      :color="{ unchecked: '#bf0909' }"
                      :height="16"
                      :labels="{ checked: 'CONF', unchecked: 'PEND' }"
                      :sync="true"
                      :width="60"
                      name="conferencePackingListSolved"
                    />
                  </div>
                  <div class="input-group-solved-after wd-100 mg-l-10">
                    <label class="form-control-label tx-12">Packing List</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-12">
          <label class="section-title mg-t-10">Declaração de Importação</label>
        </div>
      </div>

      <div class="row no-gutters">
        <!-- <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Identificação</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <input
                  v-model="model.diIdentification"
                  class="form-control wd-200"
                  name="diIdentification"
                  type="text"
                  @keydown.enter="findDIByIdentification"
                />
              </div>
              <div class="input-group-solved-after">
                <i
                  v-if="
                    model.diIdentification !== null &&
                    model.diIdentification !== ''
                  "
                  class="fa fa-check tx-success"
                ></i>
                <i v-else class="fa fa-check tx-danger"></i>
              </div>
            </div>
          </div>
        </div> -->

        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">
              Número do Protocolo da DI
            </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-center wd-150">
                <input
                  v-model="model.diProtocol"
                  class="form-control wd-200"
                  name="diProtocol"
                  type="text"
                />
              </div>
              <div class="input-group-solved-after"></div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Número do Registro</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-center wd-150">
                <input
                  v-model="model.diNumber"
                  v-mask="'##/#######-#'"
                  class="form-control wd-200"
                  name="diNumber"
                  type="text"
                />
              </div>
              <div class="input-group-solved-after mg-l-10">
                <i
                  v-if="
                    model.diNumber !== undefined &&
                    model.diNumber !== null &&
                    model.diNumber !== '' &&
                    model.diNumber.length >= 10
                  "
                  class="fa fa-check tx-success"
                ></i>
                <i v-else class="fa fa-check tx-danger"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group bd-l-0-force mg-md-t--1">
            <!--          <div class="input-group-solved">-->
            <!--            <div class="input-group-solved-before">-->
            <!--              <a class="btn btn-success btn-icon rounded-circle mg-r-10" href="#" @click.prevent='findDIByDINumber()'>-->
            <!--                <div>-->
            <!--                  <i class="icon ion-archive"></i>-->
            <!--                </div>-->
            <!--              </a>-->
            <!--            </div>-->
            <!--            <div class="input-group-solved-after ml-2">-->
            <!--              Clique no botão ao lado para adicionar a DI no processo.-->
            <!--            </div>-->
            <!--          </div>-->
            <div>
              <a class="text-info text-sm mt-3" @click="openModal" href="#">
                <i class="fa fa-info-circle"></i> Agora você não precisa mais
                consultar as DIs <br />
                Clique aqui para saber como está funcionado!
              </a>

              <VModal
                title="Consultar DIs"
                description="Veja o que mudou em relação a nova forma de consultar DI."
                :width="600"
                @requestClose="closeModal"
                :handler="isOpenModal"
              >
                <template #content>
                  <div>
                    <p>
                      Com o intuito de automatizar o processo e reduzir
                      inconsistências, mudamos o processo padrão de consulta de
                      DI. Agora o processo é feito automaticamente ao fazer o
                      upload do XML da DI para os documentos, porém é necessário
                      que o campo <strong>NÚMERO DA DI</strong> e
                      <strong>PROTOCOLO</strong> estejam preenchidos.
                      <br />
                      <br />
                      Antes de consultar a DI no Siscomex, o Gescomex efetua
                      algumas verificações para garantir a integridades das
                      informações, são elas:
                    </p>
                    <ul class="mg-t-0">
                      <li>
                        Verifica se o Número da DI que está no XML é o mesmo
                        informado no processo;
                      </li>
                      <li>
                        Verificado o CNPJ do Cliente no XML com o informado no
                        cadastro do cliente de processo;
                      </li>
                    </ul>

                    <p>
                      Além das verificações, após o upload do XML ser efetuado
                      com sucesso, o sistema salva automaticamente as seguintes
                      informações:
                    </p>
                    <ul class="mg-t-0">
                      <li>Data de Registro;</li>
                      <li>Data de Desembaraço;</li>
                    </ul>

                    <hr />

                    <h5><strong>Automações adicionais: </strong></h5>
                    <p>
                      Ao fazer upload da CI, o sistema irá consultar
                      automaticamente a DI no Sicomex, para manter o processo
                      atualizado.
                      <br />
                      <br />
                      Quando o processo for da Duas Rodas e tiver embarque
                      informado na aba de integração, o Gescomex irá efetuar a
                      consulta no sistema da Duas Rodas.
                    </p>
                  </div>
                </template>
              </VModal>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3">
          <div class="form-group bd-l-0-force mg-md-t--1">
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <a
                  class="btn btn-danger btn-icon rounded-circle mg-r-10"
                  href="#"
                  @click.prevent="
                    $store.dispatch(
                      'ProcessStore/deleteProcessImportDeclaration',
                      { process: model, showAlert: true, showLoader: true }
                    )
                  "
                >
                  <div>
                    <i class="icon ion-trash-a"></i>
                  </div>
                </a>
              </div>
              <div class="input-group-solved-after ml-2">
                Clique no botão ao lado para remover a DI deste processo.
              </div>
            </div>
          </div>
        </div> -->

        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1 bd-t-0-force">
            <label class="form-control-label">Data de Digitação</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.diTypingDate"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="diTypingDate"
                  lang="pt-br"
                  name="diTypingDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1 bd-t-0-force">
            <label class="form-control-label">Data de Registro</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.diRegistryDate"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="diRegistryDate"
                  lang="pt-br"
                  name="diRegistryDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1 bd-t-0-force">
            <label class="form-control-label">Data de Desembaraço</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.diResourcefulnessDate"
                  :disabled="abertaField"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="diResourcefulnessDate"
                  lang="pt-br"
                  name="diResourcefulnessDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group bd-t-0-force mg-md-t--1">
            <label class="form-control-label">Canal</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i
                  v-if="model.diChannel === 'VERDE'"
                  class="fa fa-tags tx-success"
                ></i>
                <i
                  v-else-if="model.diChannel === 'AMARELO'"
                  class="fa fa-tags tx-warning"
                ></i>
                <i
                  v-else-if="model.diChannel === 'VERMELHO'"
                  class="fa fa-tags tx-danger"
                ></i>
                <i
                  v-else-if="model.diChannel === 'CINZA'"
                  class="fa fa-tags"
                ></i>
                <i v-else class="fa fa-tags op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <select
                  v-model="model.diChannel"
                  class="custom-select form-control wd-100"
                  name="diChannel"
                >
                  <option value></option>
                  <option value="VERDE">Verde</option>
                  <option value="AMARELO">Amarelo</option>
                  <option value="VERMELHO">Vermelho</option>
                  <option value="CINZA">Cinza</option>
                </select>
              </div>
              <div class="input-group-solved-after"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-12">
          <label class="section-title mg-t-10">Faturamento</label>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Período de Armazenagem</label>
            <v-select
              v-model="model.storagePeriod"
              :options="storagePeriodsOptions"
              index="value"
              label="description"
              name="storagePeriod"
            >
              <template slot="option" slot-scope="option">
                {{ option.description }}
              </template>
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">NF Emissão</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.billingNFsEmissionDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="billingNFsEmissionDate"
                  lang="pt-br"
                  name="billingNFsEmissionDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.billingNFsEmissionDateSolved"
                    name="billingNFsEmissionDateSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isCristalMaster" class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1 bg-gray-200">
            <label class="form-control-label tx-danger tx-bold"
              >Número NFe</label
            >
            <input
              v-model="model.billingOutgoingNFNumber"
              class="form-control"
              name="billingOutgoingNFNumber"
              type="text"
            />
          </div>
        </div>

        <div v-if="isCristalMaster" class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1 bg-gray-200">
            <label class="form-control-label tx-danger tx-bold"
              >Valor Total NFe</label
            >
            <money
              v-model.lazy="model.billingOutgoingNFTotalValue"
              class="form-control"
              name="cifValue"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Notas Fiscais</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.billingNFsDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="billingNFsDate"
                  lang="pt-br"
                  name="billingNFsDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.billingNFsDateSolved"
                    name="billingNFsDateSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-1-force mg-md-t--1">
            <label class="form-control-label">Faturamento do Cliente</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="model.billingBillCustomerDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="billingBillCustomerDate"
                  lang="pt-br"
                  name="billingBillCustomerDate"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.billingBillCustomerDateSolved"
                    name="billingBillCustomerDateSolved"
                    placeholder="Selecione"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br clear="all" />
    </form>

    <VModal
      title="Adicionar Cliente"
      description=""
      :width="1400"
      @requestClose="modalContactOpen = false"
      :handler="modalContactOpen"
    >
      <template #content>
        <AddCustomer inModal :redirect="false" @finish="closeCustomerModal" />
      </template>
    </VModal>

    <VModal
      :handler="modalDatesEstimatedGoodsReadinesDate"
      title="Informações para o Numerário Previsto"
      description=""
      :width="600"
      :height="20"
      @requestClose="closeModalDatesEstimatedGoodsReadinesDate"
      classModify="remove-overflow"
    >
      <template #content>
        <div class="span-12">
          <p class="text-md">
            Quantos dias serão adicionados ao numerário previsto?
          </p>
          <select-wrapper
            v-show="model.wayOfTransport == 'MARITIMA'"
            label="Selecione uma opção"
            v-model="modalData.value"
            :options="modalData.optionsMARITIMA"
          />
          <select-wrapper
            v-show="model.wayOfTransport == 'AEREA'"
            label="Selecione uma opção"
            v-model="modalData.value"
            :options="modalData.optionsAEREO"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button
            class="crm-button"
            @click.prevent="updateEstimatedStepCashRequest"
          >
            Salvar
          </button>
        </div>
      </template>
    </VModal>
  </div>
</template>

<script>
import Axios from "axios";
import * as constants from "@/consts.js";
import config from "@/config.js";
import { mapState, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";
import { Money } from "v-money";
import vSelect from "vue-select";
import ProcessService from "@/services/ProcessService.js";
import _ from "lodash";
import Swal from "sweetalert2";
import { EventBus } from "@/events/EventBus.js";
import VModal from "@/components/VModal.vue";
import AddCustomer from "@/components/crm/AddCustomer.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import moment from "moment";

export default {
  name: "operational-imp-form-general",
  props: ["data"],
  components: {
    AddCustomer,
    DatePicker,
    ToggleButton,
    Money,
    "v-select": vSelect,
    VModal,
    MultiSelect,
    SelectWrapper,
  },
  data() {
    return {
      modalDatesEstimatedGoodsReadinesDate: false,
      modalData: {
        value: null,
        optionsAEREO: [
          {
            label: "Aéreo -  Europa / USA + 7 dias",
            value: 7,
          },
          {
            label: "Aéreo - Asia + 15 dias",
            value: 15,
          },
        ],
        optionsMARITIMA: [
          {
            label: "Marítimo Europa / USA + 30 dias",
            value: 30,
          },
          {
            label: "Marítimo - Asia  + 60 dias",
            value: 60,
          },
        ],
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      model: {},
      coinOptions: [],
      initialModel: {},
      listOfIncoterms: constants.OPERATIONAL_INCOTERMS,
      listOfWayOfTransport: constants.OPERATIONAL_WAY_OF_TRANSPORTE,
      listOfTypeOfBoarding: constants.OPERATIONAL_TYPE_OF_BOARDING,
      listOfUseOfGoods: constants.USE_OF_GOODS,
      listOfTypeOfProcess: constants.LIST_TYPE_OF_PROCESS,
      search_accounts: [],
      di: null,
      listOfRecintosAlfandegados: [],
      abertaField: false,
      isOpenModal: false,
      storagePeriodsOptions: [
        {
          value: "PRIMEIRO_PERIODO",
          description: "Primeiro período",
        },
        {
          value: "SEGUNDO_PERIODO",
          description: "Segundo período",
        },
        {
          value: "TERCEIRO_PERIODO",
          description: "Terceiro período",
        },
        {
          value: "QUARTO_PERIODO",
          description: "Quarto período",
        },
        {
          value: "OUTROS",
          description: "Outros",
        },
      ],
      customers: [],
      modalContactOpen: false,
    };
  },
  methods: {
    async getCustomers() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Carregando empresas.",
      });
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllCustomers`
        );
        this.customers = response.data.data.map((item) => ({
          ...item,
          _links: {
            self: {
              href: `${config.env.API_DEFAULT.host}/companies/${item.id}`,
            },
          },
        }));
      } catch (error) {
        Swal.fire({
          title: "Erro ao buscar moedas",
          text: e.response.data.message,
          title: "Listagem de Contatos",
          html: "<p>Não foi possível recuperar os contatos</p>",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async closeCustomerModal(customer = null) {
      if (customer != null) {
        await this.getCustomers();
        this.model.customer = customer;
      }
      this.modalContactOpen = false;
      this.diffObjects("customer");
    },
    closeModalDatesEstimatedGoodsReadinesDate() {
      this.modalDatesEstimatedGoodsReadinesDate = false;
      this.modalData.value = null;
    },
    changeWayOfTransport() {
      if (!this.model.datesETA) {
        this.modalData.value = null;
		
		if (this.model.daysToStepCashRequestCalculation) {
			this.updateEstimatedStepCashRequest(false);
		}

        if (
          this.model.wayOfTransport == "MARITIMA" ||
          this.model.wayOfTransport == "AEREA"
        ) {
          setTimeout(() => {
            this.modalDatesEstimatedGoodsReadinesDate = true;
          }, 600);
        }
      }
    },

    async updateEstimatedStepCashRequest(closeModal = true) {
      this.$store.commit("setLoading", {
        show: true,
        label: `Numerário Previsto`,
        description: "Atualizando...",
      });
      try {
        this.model.daysToStepCashRequestCalculation = this.modalData.value;
        this.diffObjects("daysToStepCashRequestCalculation");
      } catch (error) {
        console.error(error);

        Swal.fire({
          title: "Erro ao atualizar numerário previsto",
          text: error.response.data.message,
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });

        if (closeModal) {
          this.closeModalDatesEstimatedGoodsReadinesDate();
        }
      }
    },
    async getCoins() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/coins`
        );
        const coins = [
          {
            label: "Sem moeda",
            value: "",
          },
        ];

        for (let i = 0; i < response.data._embedded.coins.length; i++) {
          const coin = response.data._embedded.coins[i];
          if (coin.swiftCode) {
            coins.push({
              label: `${coin.swiftCode} - ${coin.name}`,
              value: coin.swiftCode,
            });
          }
        }

        this.coinOptions = coins;
      } catch (e) {
        console.error(e);

        Swal.fire({
          title: "Erro ao buscar moedas",
          text: e.response.data.message,
          type: "error",
        });
      }
    },
    validaIncoterm(e) {
      const value = e.target.value;

      if (
        value == "CIF" &&
        this.model.stepInsuranceValueResponsability == "PRIME"
      ) {
        Swal.fire({
          title: "Processo CIF com seguro Prime",
          html: `
            <p>
              Você realmente deseja alterar o incoterm para <b>CIF</b> e manter o responsável pelo seguro como <b>Prime</b>?
            </p>
          `,
          type: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Marcar como não se aplica",
          confirmButtonAriaLabel: "Marcar como não se aplica",
          cancelButtonText: "Manter o seguro com a Prime",
          cancelButtonAriaLabel: "Manter o seguro com a Prime",
        }).then((response) => {
          if (response.value) {
            this.model.stepInsuranceValueResponsability = "NAO_SE_APLICA";
          }
        });
      }

      this.model.incoterm = value;
    },
    validaResponsavelSeguro(e) {
      const value = e.target.value;

      Swal.fire({
        title: "Responsável pelo seguro",
        html: `
            <p>
              Você realmente deseja alterar o <b>responsável pelo seguro</b>?
            </p>
          `,
        type: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim, tenho certeza",
        confirmButtonAriaLabel: "Sim, tenho certeza",
        cancelButtonText: "Não, cancelar",
        cancelButtonAriaLabel: "Não, cancelar",
      }).then((response) => {
        if (response.value) {
          this.model.stepInsuranceValueResponsability = value;
        } else {
          e.target.value = this.model.stepInsuranceValueResponsability;
        }
      });
    },
    ...mapActions("ProcessStore", [
      "verifyRadarIsChecked",
      "enableNoClick",
      "findProcessByIdentification",
      "findProcessById",
    ]),
    changeTypeOfProcess() {
      if (this.model.typeOfProcess != null && this.model.typeOfProcess != "") {
        Swal.fire({
          title: "Tipo de Processo",
          html: "Você realmente deseja alterar o <b>Tipo de Processo</b>?",
          type: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Sim, alterar!',
          confirmButtonAriaLabel: "Sim, alterar!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
          cancelButtonAriaLabel: "Cancelar",
        }).then((result) => {
          this.$store.commit("setLoading", {
            show: true,
            label: "Alterando tipo de processo",
            description:
              "Aguarde, estamos alterando a ordem das referências...",
          });

          if (result.value) {
            Axios.put(
              config.env.API_DEFAULT.host +
                `/newImportProcess/change-type-of-process/${this.model.id}/${this.model.typeOfProcess}`
            )
              .then((response) => {
                this.model = response.data.data;
                this.$store.commit(
                  "ProcessStore/SET_ACTIVE",
                  response.data.data
                );
                this.$store.commit(
                  "ProcessStore/SET_SINGLE_PROCESS",
                  response.data.data
                );
                Swal.fire(
                  "Tipo de Processo",
                  "O Tipo deste Processo foi alterado com sucesso <i class'fa fa-thumbs-up'></i> ",
                  "success"
                );
              })
              .catch((error) => {
                Swal.fire(
                  "Não foi possível atualizar",
                  error.response.data.message,
                  "error"
                );
              });
          } else {
            this.model.typeOfProcess = this.initialModel.typeOfProcess;
          }

          this.$store.commit("setLoading", {
            show: false,
          });
        });
      }
    },
    changeIdentification() {
      var self = this;
      var oldIdentification = self.model.identification;

      Swal.fire({
        title: "Referência Interna",
        html:
          "Você realmente deseja alterar a referência interna <br /> atual deste processo? A referência atual é <b>" +
          self.model.identification +
          "</b>.",
        type: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Sim, alterar!',
        confirmButtonAriaLabel: "Sim, alterar!",
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
        cancelButtonAriaLabel: "Cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            type: "info",
            title: "Nova Referência Interna",
            text: "Digite a nova referência interna deste processo:",
            input: "number",
            inputAttributes: {
              autocapitalize: "on",
            },
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: "##23BF08",
            cancelButtonColor: "#dc3545",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (referencia) => {
              self.model.identification = referencia;

              return ProcessService.verifyIfIdentificationExists(
                self.model.id,
                referencia,
                self.model.typeOfProcess
                  ? self.model.typeOfProcess
                  : "CONTA_E_ORDEM"
              )
                .then((r) => {
                  if (r.data.data === false) {
                    Axios.put(
                      config.env.API_DEFAULT.host +
                        `/newImportProcess/change-manual-identification/${self.model.id}/${referencia}`
                    ).then((res) => {
                      this.$store.commit(
                        "ProcessStore/SET_ACTIVE",
                        res.data.data
                      );
                      this.$store.commit(
                        "ProcessStore/SET_SINGLE_PROCESS",
                        res.data.data
                      );
                    });

                    const response = this.$store.dispatch(
                      "ProcessStore/diffObjects",
                      {
                        initialModel: self.initialModel,
                        model: self.model,
                        fieldName: "identification",
                      }
                    );

                    if (response) {
                      self.initialModel = { ...self.model };
                    }
                  } else {
                    self.model.identification = oldIdentification;
                    Swal.fire(
                      "Referência Interna em Uso",
                      "A referência " +
                        referencia +
                        " já está em uso por outro processo.",
                      "error"
                    );
                    Swal.fire({
                      type: "error",
                      title: "Referência Interna em Uso",
                      html:
                        "A Referência Interna deste processo não pode ser alterada!<br />Já existe um processo com a Referência Interna <b>" +
                        referencia +
                        "</b>.",
                      showCloseButton: true,
                    });
                    return false;
                  }
                })
                .catch((e) => {
                  self.model.identification = oldIdentification;
                  Swal.fire(
                    "Salvar",
                    "Não foi possível salvar o processo: " + e,
                    "error"
                  );
                  return false;
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.value) {
              Swal.fire({
                type: "success",
                title: "Nova Referência Interna",
                html:
                  "A Referência Interna deste processo foi<br/> alterada com sucesso de <b>" +
                  oldIdentification +
                  "</b> para <b>" +
                  self.model.identification +
                  "</b>.",
                showCloseButton: true,
              });
            }
          });
        }
      });
    },
    formatDIValues(val) {
      var number = new String(parseInt(val));
      var decimal = number.substring(number.length - 2, number.length);
      var rest = number.substring(0, number.length - 2);

      return parseFloat(rest + "." + decimal);
    },
    formatDIValuesWithDecimal(val, decimals) {
      var number = new String(parseInt(val));
      var decimal = number.substring(number.length - decimals, number.length);
      var rest = number.substring(0, number.length - decimals);

      return parseFloat(rest + "." + decimal);
    },
    formatDIDateValues(date) {
      return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
    },
    async findDIByDINumber(event) {
      this.enableNoClick(true);
      this.$store.dispatch("ProcessStore/deleteProcessImportDeclaration", {
        process: this.model,
        showAlert: false,
        showLoader: false,
      });
      // if(del) {
      if (
        this.model.diNumber !== undefined &&
        this.model.diNumber !== null &&
        this.model.diNumber.length === 12
      ) {
        const response = await this.$store.dispatch(
          "MyIMPStore/findDIByDINumber",
          this.model.diNumber
        );

        if (response.data.code == "SUCCESS") {
          var data = {
            diIdentification: response.data.data.numeroDI,
            diNumber: response.data.data.numeroDI,
            diRegistryDate: response.data.data.dataRegistroDI,
            diChannel: response.data.data.canal,
            diResourcefulnessDate:
              response.data.data.dataDesembaracoDI !== null
                ? response.data.data.dataDesembaracoDI
                : null,
            jsonDeclaracaoImportacaoDigitada: JSON.stringify(
              response.data.data
            ),
          };

          if (response.data.data.canalDescricao !== null) {
            data.diChannel = response.data.data.canalDescricao;
            this.model.diChannel = data.diChannel;
          }

          this.model.diIdentification = response.data.data.numeroDI;
          this.model.diID = data.diID;
          this.model.diRegistryDate = data.diRegistryDate;
          // this.model.diTypingDate = data.diTypingDate;
          this.model.diResourcefulnessDate = data.diResourcefulnessDate;
          this.model.jsonDeclaracaoImportacaoDigitada =
            data.jsonDeclaracaoImportacaoDigitada;
          var importDeclaration = {
            registryNumber: this.model.diNumber,
            registryDate: this.model.diRegistryDate,
            resourcefulnessDate: this.model.diResourcefulnessDate,
            valueVMLDUSD: this.formatDIValues(
              response.data.data.localDescargaTotalDolares
            ),
            valueVMLDBRL: this.formatDIValues(
              response.data.data.localDescargaTotalReais
            ),
            valueVMLEUSD: this.formatDIValues(
              response.data.data.localEmbarqueTotalDolares
            ),
            valueVMLEBRL: this.formatDIValues(
              response.data.data.localEmbarqueTotalReais
            ),
            valueII: 0.0,
            valueIPI: 0.0,
            valuePISPasep: 0.0,
            valueCofins: 0.0,
            valueAntidumping: 0.0,
            valueUSDFare: 0.0,
            cargoCountryOriginCode:
              response.data.data.cargaPaisProcedenciaCodigo,
            cargoCountryOriginName: response.data.data.cargaPaisProcedenciaNome,
            importProcess:
              config.env.API_DEFAULT.host + "/importProcesses/" + this.model.id,
            customer:
              config.env.API_DEFAULT.host +
              "/companies/" +
              this.model.customer.id,
            freteCollect: this.formatDIValues(response.data.data.freteCollect),
            fretePrepaid: response.data.data.fretePrepaid,
            coinCode: response.data.data.freteMoedaNegociadaCodigo,
            totalFreight: this.formatDIValues(
              response.data.data.freteTotalReais
            ),
            nationalTerritoryShipping:
              response.data.data.freteEmTerritorioNacional,
            placeOfOrigin: response.data.data.conhecimentoCargaEmbarqueLocal,
            arrivalDate: this.formatDIDateValues(
              response.data.data.cargaDataChegada
            ),
            shipmentDate: this.formatDIDateValues(
              response.data.data.conhecimentoCargaEmbarqueData
            ),
          };

          if (
            response.data.data.pagamento !== undefined &&
            response.data.data.pagamento !== null
          ) {
            response.data.data.pagamento.forEach((p) => {
              /// II
              if (p.codigoReceita === "0086") {
                importDeclaration.valueII = this.formatDIValues(p.valorReceita);
              }

              //IPI
              if (p.codigoReceita === "1038") {
                importDeclaration.valueIPI = this.formatDIValues(
                  p.valorReceita
                );
              }

              //PIS
              if (p.codigoReceita === "5602") {
                importDeclaration.valuePISPasep = this.formatDIValues(
                  p.valorReceita
                );
              }

              //Cofins
              if (p.codigoReceita === "5629") {
                importDeclaration.valueCofins = this.formatDIValues(
                  p.valorReceita
                );
              }

              //Antidumping
              if (p.codigoReceita === "5529") {
                importDeclaration.valueAntidumping = this.formatDIValues(
                  p.valorReceita
                );
              }

              //Siscomex
              if (p.codigoReceita === "7811") {
                importDeclaration.valueSiscomexFare = this.formatDIValues(
                  p.valorReceita
                );
              }
            });
          }

          try {
            this.$store.commit("setLoading", {
              show: true,
              label: "Declaração de Importação",
              description:
                "Verificando se DI já está em nosso banco de dados...",
            });
            const responseFindByRegistryNumber = await Axios.get(
              config.env.API_DEFAULT.host +
                "/processImportDeclarations/search/findByRegistryNumber?registryNumber=" +
                encodeURI(importDeclaration.registryNumber)
            );
            if (responseFindByRegistryNumber) {
              try {
                const processImportDeclarationsResponse = await Axios.patch(
                  config.env.API_DEFAULT.host +
                    "/processImportDeclarations/" +
                    responseFindByRegistryNumber.data.id,
                  importDeclaration
                );
                this.$store.commit("setLoading", {
                  show: true,
                  label: null,
                  description: null,
                });
                if (processImportDeclarationsResponse) {
                  Swal.fire(
                    "Declaração de Importação",
                    "Declaração de Importação salva com sucesso no Processo de Importação!",
                    "success"
                  );
                }
              } catch (error) {
                Swal.fire(
                  "Declaração de Importação",
                  "Não foi possível vincular as informações de checagem da DI no Processo de Importação." +
                    error,
                  "error"
                );
                this.$store.commit("setLoading", {
                  show: true,
                  label: null,
                  description: null,
                });
              }
            }
          } catch (error) {
            if (
              error.response !== undefined &&
              error.response.status !== undefined &&
              error.response.status === 404
            ) {
              try {
                this.$store.commit("setLoading", {
                  show: true,
                  label: "Declaração de Importação",
                  description: "Salvando a DI em nosso banco de dados...",
                });
                const processImportDeclarationsResponse = await Axios.post(
                  config.env.API_DEFAULT.host + "/processImportDeclarations",
                  importDeclaration
                );
                if (processImportDeclarationsResponse) {
                  let totalAdicoes = 0;
                  const adicoes = response.data.data.adicao;
                  for (let i = 0; i < adicoes.length; i++) {
                    try {
                      const adicaoData = {
                        number: adicoes[i].numeroAdicao,
                        ncm: adicoes[i].dadosMercadoriaCodigoNcm,
                        ncmDescription: adicoes[i].dadosMercadoriaNomeNcm,
                        liNumber: adicoes[i].numeroLI,
                        provider: adicoes[i].fornecedorNome,
                        totalItems: adicoes[i].mercadoria.length,
                        netWeightMerchandise: this.formatDIValuesWithDecimal(
                          adicoes[i].dadosMercadoriaPesoLiquido,
                          5
                        ),
                        importDeclaration:
                          config.env.API_DEFAULT.host +
                          "/processImportDeclarations/" +
                          processImportDeclarationsResponse.data.id,
                      };

                      const adicaoDataResponse = await Axios.post(
                        config.env.API_DEFAULT.host +
                          "/processImportDeclarationAdditions/",
                        adicaoData
                      );
                      if (adicaoDataResponse) {
                        totalAdicoes += 1;
                      }
                    } catch (error) {
                      this.$store.commit("setLoading", { show: false });
                      Swal.fire(
                        "Declaração de Importação",
                        "Não foi possível vincular a adição " +
                          adicoes[i].numeroAdicao +
                          " no Processo Importação.",
                        "error"
                      );
                    }
                  }
                  this.$store.commit("setLoading", { show: false });

                  if (totalAdicoes === adicoes.length) {
                    Swal.fire(
                      "Declaração de Importação",
                      "Declaração de Importação salva com sucesso no Processo de Importação!",
                      "success"
                    );
                  } else {
                    Swal.fire(
                      "Declaração de Importação",
                      "Não foi possível vincular todas as adições no Processo de Importação!",
                      "error"
                    );
                  }
                }
              } catch (error) {
                this.$store.commit("setLoading", { show: false });
                Swal.fire(
                  "Declaração de Importação",
                  "Não foi possível salvara a DI neste Processo de Importação: " +
                    error,
                  "error"
                );
              }
            }
          }

          Axios.patch(
            config.env.API_DEFAULT.host + "/importProcesses/" + this.model.id,
            data
          )
            .then((r) => {
              this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
              this.$store.commit("ProcessStore/SET_SINGLE_PROCESS", this.model);
              this.$store.dispatch(
                "ProcessStore/findProcessByIdentification",
                this.process.identification
              );
            })
            .catch((error) => {});
          this.$store.commit("setLoading", { show: false });
          this.enableNoClick(false);
        } else {
          this.$store.commit("setLoading", { show: false });
          Swal.fire(
            "Buscar Declaração de Importação",
            response.data.message,
            "error"
          );
          this.enableNoClick(false);
        }
      } else {
        this.$store.commit("setLoading", { show: false });
        Swal.fire(
          "Buscar Declaração de Importação",
          "Digite corretamente o número da Declaração da Importação, pois " +
            this.model.diNumber +
            " não é válido.",
          "error"
        );
        this.enableNoClick(false);
      }
    },
    async findDIByIdentification() {
      const response = await this.$store.dispatch(
        "MyIMPStore/getDIToImportProcess",
        this.model.diIdentification
      );

      if (response.data.code == "SUCCESS") {
        var data = {
          diID: response.data.data.id,
          diNumber: response.data.data.numeroRegistro,
          diRegistryDate: response.data.data.dataRegistro,
          diTypingDate: response.data.data.dataDigitacao,
          diResourcefulnessDate:
            response.data.data.dataDesembaraco !== null &&
            response.data.data.dataDesembaraco !== undefined &&
            response.data.data.dataDesembaraco !== ""
              ? response.data.data.dataDesembaraco
              : null,
          diChannel: response.data.data.canal,
          jsonDeclaracaoImportacaoDigitada: JSON.stringify(
            response.data.data.di
          ),
        };

        this.model.diID = data.diID;
        this.model.diNumber = data.diNumber;
        this.model.diRegistryDate = data.diRegistryDate;
        this.model.diTypingDate = data.diTypingDate;
        this.model.diResourcefulnessDate =
          response.data.data.dataDesembaraco !== null &&
          response.data.data.dataDesembaraco !== undefined &&
          response.data.data.dataDesembaraco !== ""
            ? response.data.data.dataDesembaraco
            : null;
        this.model.diChannel = data.diChannel;
        this.model.jsonDeclaracaoImportacaoDigitada = data.di;

        Axios.patch(
          config.env.API_DEFAULT.host + "/importProcesses/" + this.model.id,
          data
        )
          .then((r) => {
            this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
            this.$store.commit("ProcessStore/SET_SINGLE_PROCESS", this.model);

            Swal.fire(
              "Buscar Declaração de Importação",
              response.data.message,
              "success"
            );
          })
          .catch((error) => {});
      } else Swal.fire("Buscar Declaração de Importação", "Não foi .", "error");
    },

    generateNewIdentification() {
      let self = this;
      Axios.get(
        config.env.API_DEFAULT.host +
          "/process/import/generateNewIdentification"
      )
        .then((r) => {
          self.model.identification = r.data.data.next;

          var step = null;

          if (this.steps !== null && this.steps !== undefined) {
            step = this.steps.filter((step) => {
              return step.first;
            });

            if (
              step[0] !== null &&
              step[0] !== undefined &&
              step[0]._links !== undefined &&
              step[0]._links.self !== undefined
            ) {
              this.model.step = step[0]._links.self.href;
              this.diffObjects("identification");
            } else {
              Swal.fire(
                "Processo de Importação",
                "Verifique as configurações das etapas e certifique-se de que existe uma etapa configurada como etapa inicial. <small>(É necessário ter uma etapa inicial configurada para que o processo seja aberto)</small>",
                "error"
              );
            }
          }
        })
        .catch((e) => {
          Swal.fire(
            "Referência Interna",
            "Não foi possível gerar uma Referência Interna para este processo.",
            "error"
          );
        });
    },
    getCompanies: _.debounce(function (search) {
      this.$store
        .dispatch("ProcessStore/getCompanies", search)
        .then((response) => {})
        .catch((error) => {
          Swal.fire(
            "Empresas",
            "Ops, houve algum erro no carregamento das empresas! ",
            "error"
          );
        });
    }, 500),
    getCustomersBrokers: _.debounce(function (search) {
      this.$store
        .dispatch("ProcessStore/getCustomersBrokers", search)
        .then((response) => {})
        .catch((error) => {
          Swal.fire(
            "Empresas",
            "Ops, houve algum erro no carregamento das empresas! " + error,
            "error"
          );
        });
    }, 500),
    getForwarders: _.debounce(function (search) {
      this.$store
        .dispatch("ProcessStore/getForwarders", search)
        .then((response) => {})
        .catch((error) => {
          Swal.fire(
            "Empresas",
            "Ops, houve algum erro no carregamento das empresas! ",
            "error"
          );
        });
    }, 500),
    getShipowners: _.debounce(function (search) {
      this.$store
        .dispatch("ProcessStore/getShipowners", search)
        .then((response) => {})
        .catch((error) => {
          Swal.fire(
            "Empresas",
            "Ops, houve algum erro no carregamento das empresas! ",
            "error"
          );
        });
    }, 500),
    getExporters: _.debounce(function (search) {
      this.$store
        .dispatch("ProcessStore/getExporters", search)
        .then((response) => {})
        .catch((error) => {
          Swal.fire(
            "Exportadores / Fabricantes",
            "Ops, houve algum erro no carregamento das empresas! ",
            "error"
          );
        });
    }, 500),

    getReceitaFederalUnits: _.debounce(async function (search) {
      this.$store
        .dispatch("ProcessStore/getReceitaFederalUnits", search)
        .then((response) => {
          this.listOfRecintosAlfandegados = response;
        })
        .catch((error) => {
          Swal.fire(
            "URF de Entrada",
            "Ops, houve algum erro no carregamento das informações! ",
            "error"
          );
        });
    }, 500),
    getCustomsEnclosures: _.debounce(async function (search) {
      this.$store
        .dispatch("ProcessStore/getCustomsEnclosures", search)
        .then((response) => {
          // this.listOfRecintosAlfandegados = response;
          // this.$set(this, "listOfRecintosAlfandegados", response);
        })
        .catch((error) => {
          Swal.fire(
            "Recintos Alfandegados",
            "Ops, houve algum erro no carregamento das informações! " + error,
            "error"
          );
        });
    }, 500),
    getAccounts: function (search, loading) {
      loading(true);
      Axios.get(
        config.env.API_DEFAULT.host +
          "/accounts/search/findAllByNameContainingIgnoreCase?name=" +
          search
      )
        .then((response) => {
          this.search_accounts = response.data._embedded.accounts;
          loading(false);
        })
        .catch(function (error) {
          swal(
            "Não foi possível carregar a listagem de Filiais disponíveis: " +
              error,
            "error"
          );
        });
    },

    async checkIfThereIsDI() {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarDIByIdentification",
        this.model.identification
      );
      if (response) {
        this.di = response.data;
      }
    },
    async diffObjects(fieldName) {
      if (
        this.model.id !== null &&
        this.model.id !== undefined &&
        this.model.identification !== null &&
        this.model.identification !== undefined &&
        this.model.typeOfProcess !== null &&
        this.model.typeOfProcess !== undefined
      ) {
        await ProcessService.verifyIfIdentificationExists(
          this.model.id,
          this.model.identification,
          this.model.typeOfProcess
        )
          .then((r) => {
            var identification = this.model.identification;

            if (r.data.data === false) {
              const response = this.$store.dispatch(
                "ProcessStore/diffObjects",
                {
                  initialModel: this.initialModel,
                  model: this.model,
                  fieldName: fieldName,
                }
              );

              if (response) {
                this.initialModel = { ...this.model };
              }
            } else {
              if (
                this.initialModel !== null &&
                this.initialModel !== undefined
              ) {
                this.model.identification = this.initialModel.identification;
              } else {
                this.model.identification = "";
              }

              Swal.fire(
                "Referência Interna em Uso",
                "A referência " +
                  identification +
                  " já está em uso por outro processo.",
                "error"
              );
            }
          })
          .catch((e) => {
            this.$store.commit("setLoading", { show: false });
            Swal.fire(
              "Salvar",
              "Não foi possível salvar o processo: " + e,
              "error"
            );
          });
      } else if (
        (this.model.id === null || this.model.id === undefined) &&
        this.model.identification !== null &&
        this.model.identification !== undefined
      ) {
        this.model.step = config.env.API_DEFAULT.host + "/processSteps/7";
        const response = this.$store.dispatch(
          "ProcessStore/postImportProcess",
          {
            model: this.model,
          }
        );

        if (response) {
          this.initialModel = {
            ...this.model,
          };

          Swal.fire(
            "Novo Processo de Importação",
            "O processo foi salvo sob a referência interna de nº <b>" +
              this.model.identification +
              "</b>.",
            "success"
          );

          this.model.id = this.process.id;
          this.model.identification = this.process.identification;
        }
      }
    },
    openModal() {
      this.isOpenModal = true;
    },
    closeModal() {
      this.isOpenModal = false;
    },
  },
  computed: {
    ...mapState("ProcessStore", [
      "hasTFADocument",
      "accounts",
      "steps",
      "optionsCompanies",
      "optionsCustomers",
      "optionsExporters",
      "optionsCustomersBrokers",
      "optionsForwarders",
      "optionsShipowners",
      "optionsReceitaFederalUnits",
      "optionsCustomsEnclosures",
      "listOfURFs",
    ]),
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      StateNoClick: (state) => state.ProcessStore.noClick,
    }),
    hasWithoutOperationalTeamAlert() {
      if (this.model.team) {
        if (
          (this.model.typeOfProcess === "CONTA_E_ORDEM" &&
            this.model.customer.team === null) ||
          (this.model.typeOfProcess === "ENCOMENDA" &&
            this.model.customer.teamOrder === null) ||
          (this.model.typeOfProcess === "ASSESSORIA" &&
            this.model.customer.teamAdvisory === null)
        ) {
          return true;
        }
      }

      return false;
    },
    isCristalMaster() {
      if (
        this.model !== undefined &&
        this.model !== null &&
        this.model.customer !== undefined &&
        this.model.customer !== null
      ) {
        return (
          this.model.customer.id == 24 ||
          this.model.customer.federalID === "05.378.317/0001-80"
        );
      }

      return false;
    },
    options() {
      return [
        "blur",
        (e) => {
          var fieldName = null;

          if (e.srcElement.name === "identification") {
            return false;
          }

          if (e.srcElement.attributes[1].name === "type") {
            fieldName = e.srcElement.attributes.name.nodeValue.toString();
          } else {
            if (e.srcElement.attributes[1] !== undefined) {
              fieldName = e.srcElement.attributes[1].nodeValue.toString();
            } else if (e.path !== undefined && e.srcElement !== undefined) {
              fieldName = e.path[0].name;
            }
          }

          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
    invoiceInconsistence() {
      let installmentsTotal = 0;

      if (this.model.invoices && this.model.invoices.length > 0) {
        this.model.invoices.forEach((invoice) => {
          installmentsTotal += invoice.installments.reduce(
            (cur, acc) => (cur += acc.value),
            0
          );
        });
      }

      installmentsTotal = Math.round(parseFloat(installmentsTotal) * 100) / 100;

      if (this.model.invoiceTotal > installmentsTotal) {
        return "Valor MAIOR que o Valor Total das Invoices";
      } else if (this.model.invoiceTotal < installmentsTotal) {
        return "Valor MENOR que o Valor Total das Invoices";
      }

      return null;
    },
  },

  async mounted() {
    this.getCustomers();
    this.getCoins();

    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("generalForm");
        if (form !== null) {
          form.addEventListener(...this.options);
        }
      }, 200);
    }

    if (this.process) {
      this.model = {
        ...this.process,
      };

      this.initialModel = {
        ...this.process,
      };

      if (this.model.id === null || this.model.identification === null) {
        this.generateNewIdentification();
      }

      if (
        this.model.customer !== undefined &&
        this.model.customer !== null &&
        this.model.customer.id !== null
      ) {
        this.verifyRadarIsChecked();
      }

      this.abertaField = false;
    }

    EventBus.$on("Operational.imp.form.general.changeWayOfTransport", () => {
      this.changeWayOfTransport();
    });
  },
  beforeDestroy() {
	EventBus.$off("Operational.imp.form.general.changeWayOfTransport");
  },
  watch: {
    process: async function () {
      this.initialModel = {
        ...this.process,
      };
      this.model = {
        ...this.process,
      };

      const integrationCustomValueIsEmpty =
        this.model.integrationCustomsValue === null ||
        this.model.integrationCustomsValue === "" ||
        this.model.integrationCustomsValue === 0;

      if (this.model.datesETA !== null && this.model.datesETA !== "") {
        this.model.estimatedStepCashRequest = this.model.datesETA;
      }

      if (
        this.model.billingNFsDate !== null &&
        this.model.billingNFsDate !== "" &&
        this.process.customer &&
        this.process.customer.id == 100 &&
        integrationCustomValueIsEmpty &&
        this.process.wayOfTransport === "MARITIMA"
      ) {
        let fieldsWithoutValue = [];

        if (integrationCustomValueIsEmpty) {
          fieldsWithoutValue.push("Despesas Aduaneiras");
        }

        let message = `${
          fieldsWithoutValue.length > 1 ? "Os Campos" : "O Campo "
        } ${fieldsWithoutValue.join(", ")} ${
          fieldsWithoutValue.length > 1
            ? " devem ser preenchidos"
            : " deve ser preenchido"
        }`;

        Swal.fire({
          title: "Preenchimento da Data de ",
          html: message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style scoped>
.add-new-contact {
  border: 0;
  cursor: pointer;
  background-color: #5b93d3;
  color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.input-way-of-transport .vs__selected-options {
  flex-wrap: nowrap;
}

.input-way-of-transport .vs__selected-options .selected-tag {
  min-width: 95px;
}
</style>
